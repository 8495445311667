import { Controller } from "@hotwired/stimulus";
import { load } from 'recaptcha-v3';
import $ from 'jquery';
import axios from "axios";

function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }


  

export default class extends Controller {
    static targets = [ "logo", "clickToPlay", "language", "formContainer" ]
    static values = {
        apiUserId: String,
        uuid: String,
        cookieId: String,
        videoFileId: String,
        providerId: String,
        jwplayerMediaId: String,
        videoUrl: String,
        isPlaying: Boolean,
        shouldDisplayWarning: Boolean,
        shouldDisplayDemo: Boolean,
        language: String,
        formIds: Array
    } 

    connect() {
        this.createSession().finally(() => {
            this.createSessionAction("page_load");
        });

        this.isPlayingOnHide = true;
        this.isCompleted = false;

        window.addEventListener("beforeunload", this.beforeUnload.bind(this));
        document.addEventListener("visibilitychange", this.visibilityChange.bind(this));

        document.addEventListener('fullscreenchange', () => {
            const isFullScreen = document.fullscreenElement !== null;
            const feedbackModal = document.getElementById('feedbackModal');
            const languageModal = document.getElementById('languageModal');
            const formModal = document.getElementById('formModal');
            const thankyouModal = document.getElementById('thankyouModal');

            if (isFullScreen) {
                // Append modals to the full screen element
                document.fullscreenElement.appendChild(feedbackModal);
                document.fullscreenElement.appendChild(languageModal);
                document.fullscreenElement.appendChild(formModal);
                document.fullscreenElement.appendChild(thankyouModal);
            } else {
                // Append modals back to the body
                document.body.appendChild(feedbackModal);
                document.body.appendChild(languageModal);
                document.body.appendChild(formModal);
                document.body.appendChild(thankyouModal);
            }
        });

        window.jwplayer.key = "t2r/I6mc5MOwVGqiGNYTdHEyVHMbrbi9tBbPBQDw9Cw=";

        this.jwplayerInstance = jwplayer("jwplayer");
        this.setLogoPosition();

        if (this.shouldDisplayWarningValue === true) {
            setTimeout(function() {
              $('#warning').fadeOut();
            }, 10000);
        }

        load('6Lc5pCIqAAAAAOK-0mRwXWJz8OUGs0N5JENdnaok',{
            autoHideBadge: true,
        }).then((recaptcha) => {
            this.recaptcha = recaptcha;
            this.setupPlayer();
        });
        
        this.loadForms();
    }

    visibilityChange(event) { 
        if (document.hidden) {
            this.isPlayingOnHide = this.jwplayerInstance.getState() === "playing";
            this.jwplayerInstance.pause();
            this.createSessionAction("page_hidden", {playback_position: this.getCurrentPositionFormatted()});
          } else {
            this.createSessionAction("page_visible", {playback_position: this.getCurrentPositionFormatted()});
            if (this.isPlayingOnHide) {
                this.jwplayerInstance.play();
            }
          }
    }

    beforeUnload(event) {
        if(this.isCompleted == true) {
            // Do nothing
            
        } else {
            //Ask if sure if they want to leave
            
            this.createSessionAction("page_unload_attempt");
            event.preventDefault();
            event.returnValue = '';
            return '';
        }
    }

    async loadForms() {
        const formContainer = this.formContainerTarget;
        let session_uuid = this.data.get("cookieId");
        let videoFileId = this.data.get("videoFileId");
        let formIds = JSON.parse(this.data.get("formIds"));
        //for (const formId of formIds) {
            const formId = formIds[0];
          const response = await axios.get(`/api/v2/forms/${formId}/render`);
          const formHtml = await response.data;
          formContainer.insertAdjacentHTML('beforeend', formHtml);
          //Disable hiding of modal

          
          $('#form').on('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(e.target);
            const formObject = {};
            formData.forEach((value, key) => {
                formObject[key] = value;
            });

            axios.post('/api/form_submissions/create', {
                form_submissions: [
                    {
                        form_id: formId,
                        created_at_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                        value: formObject,
                        video_ids: [videoFileId],
                        session_uuid: session_uuid
                    }
                ]
                
            });
            $('#formModal').modal('hide')
            $('#thankyouModal').modal('show')
          });
        //}
      }



    setLogoPosition() {
        let playerWidth = window.innerWidth
        let playerHeight = playerWidth * 0.5625
        let windowHeight = window.innerHeight
        let heightMargin = (windowHeight - playerHeight) / 2
        let logoHeight = playerHeight * 0.085

        if (heightMargin < 0) {
            heightMargin = 0;
        }

        let widthMargin = ''

        if (playerHeight > windowHeight) {
            widthMargin = (playerWidth - (windowHeight * 1.77)) / 2
            if (widthMargin < 0) {
                widthMargin = 0
            }
        }

        this.logoTarget.style.top = (heightMargin + (.1 * logoHeight)) + "px";
        this.logoTarget.style.left = (widthMargin + 20) + "px";
        this.logoTarget.style.height = logoHeight + "px";
    }

    saveVideoAction(type) {
        let videoFileId = this.data.get("videoFileId");
        let apiUserId = this.data.get("apiUserId");
        //This session is from nyimaging and is separate from regular sessions
        let uuid = this.data.get("uuid")
        $.ajax({
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            url: "/api/video_actions/record_action",
            data: { "video_action": { 
                uuid: uuid,
                video_action_type: type, 
                video_file_id: videoFileId, 
                api_user_id: apiUserId } }
          });
    }

    
    async setupPlayer() {
        let tracks = [];
        let file = this.data.get("videoUrl")
        let jwplayerMediaId = this.data.get("jwplayerMediaId")
        if (jwplayerMediaId !== null) {
            file = "//cdn.jwplayer.com/manifests/" + jwplayerMediaId + ".m3u8";
            let result = await $.get("https://cdn.jwplayer.com/v2/media/" + jwplayerMediaId)
            tracks = result.playlist[0].tracks.filter(function(track) {
                return track.kind == "captions"
            });
        }

        this.jwplayerInstance.setup({
            "playlist": [{
              "sources": [{
                "file": file,
              }],
              "tracks": tracks
            }],
            "autostart": false,
            "stretching": "uniform",
            "displaytitle": false,
            "controls": true,
            "height": "100%",
            "width": "auto"
          });

        this.jwplayerInstance.on("pause", () => {   
            this.saveVideoAction("pause");
            this.createSessionAction("pause", {playback_position: this.getCurrentPositionFormatted()});
        });
    
        this.jwplayerInstance.on("complete", () => {
            
            this.saveVideoAction("complete");
            this.isCompleted = true;
            this.createSessionAction("complete_video");
            this.showFeedbackModal()
            this.endSession();
        });

        this.jwplayerInstance.on("play", () => {
            this.isPlayingOnHide = true
            this.saveVideoAction("play");
            this.createSessionAction("play", {playback_position: this.getCurrentPositionFormatted()});
            $('#clickToPlay').hide();
        });

        this.jwplayerInstance.on("seek", () => {
            this.createSessionAction("seek", {playback_position: this.getCurrentPositionFormatted()});
        });

        this.jwplayerInstance.on("seeked", () => {
            this.createSessionAction("seeked", {playback_position: this.getCurrentPositionFormatted()});
        });

        jwplayer("jwplayer").addButton('/img/language.svg', "Language", () => {
            $('#languageModal').modal('show')
        }
        , "languageButton");
    }


    play() {
            
        this.recaptcha.execute('play').then((token) => {
            this.clickToPlayTarget.style.display = "none";
            $.ajax({
                type: "POST",
                url: "/api/v2/captcha/verify",
                data: { 
                    token: token
                }
            }).then(res => {
                this.createSessionAction("captcha", {score: res['score']});
            })
   
            this.jwplayerInstance.play();   
        });

    }

    getCurrentPositionFormatted() {
        const positionInSeconds = this.jwplayerInstance.getPosition();
        return formatTime(positionInSeconds);
    }

    changeLanguage() {

        const currentLanguage = this.data.get("language")
        const shortcodePresent = this.data.get("shortcodePresent")
        const languageParamPresent = this.data.get("languageParamPresent")
        const newLanguage = this.languageTarget.value;
        
        let currentUrl = window.location.origin + window.location.pathname;
        if(currentLanguage !== newLanguage) {
            if(shortcodePresent) {               
                if (languageParamPresent) {
                    const lastSlashIndex = currentUrl.lastIndexOf("/");
                    let newUrl = currentUrl.substring(0, lastSlashIndex);
                    newUrl =+ "/" + newLanguage;   
                } else {        
                    currentUrl =+ "/" + newLanguage;   
                } 
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const token = urlParams.get("token");
                this.createSessionAction("language", {playback_position: this.getCurrentPositionFormatted(), language: newLanguage}).then(()=>{
                    window.location.href = currentUrl + "?token=" + token + "&language=" + newLanguage;
                });
                
            }
        }

    }

    async createSession() {
        let videoFileId = this.data.get("videoFileId");
        let apiUserId = this.data.get("apiUserId");
        let cookieId = this.data.get("cookieId");
        return $.ajax({
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            url: "/sessions",
            data: { session: {
                uuid: cookieId,
                type_id: 1,
                start_time: new Date().toISOString(),
                video_file_ids: [videoFileId], 
                api_user_id: apiUserId }}
          })
    }

    createSessionAction(type, data) {
        let cookieId = this.data.get("cookieId");
        return $.ajax({
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            url: "/session_actions",
            data: { "session_action": { 
                action_type: type, 
                data: data,
                timestamp: new Date().toISOString(),
                session_uuid: cookieId
                }}
          });
    }

    endSession() {
        let cookieId = this.data.get("cookieId");
        $.ajax({
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            url: "/sessions/" + cookieId,
            data: { session: {
                end_time: new Date().toISOString(),
                videos_completed: true
             }}
          })
    }

    showFeedbackModal() {
        $('#feedbackModal').modal('show')
    }

    react(event) {
        const reactionValue = event.params.react;
        this.createSessionAction("feedback", { reaction: reactionValue });
        let videoFileId = this.data.get("videoFileId");
        let providerId = this.data.get("providerId");
        let cookieId = this.data.get("cookieId");

        let feedbackJson = [{
            id: videoFileId,
            reaction: reactionValue,
            provider_id: providerId,
            session_uuid: cookieId,
            created_at_date: new Date().toISOString().slice(0, 19).replace('T', ' ')
        }];

        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

        axios.post("/api/video_files/rate/", {
            _json: feedbackJson})

        let formIds = JSON.parse(this.data.get("formIds"));
        if (formIds.length > 0) {
            $('.video-player__logo').hide();
            $('#formModal').modal({
                backdrop: 'static',
                keyboard: false 
                })
              
            $('#formModal').modal('show');
            $('#feedbackModal').modal('hide')

        } else {
            $('#feedbackModal').modal('hide')
            $('#thankyouModal').modal('show')
        }    
        
    }

    replay() {
        this.jwplayerInstance.seek(0);
        this.jwplayerInstance.play();
        this.createSessionAction("restart");
        $('#feedbackModal').modal('hide');
    }
}
